import { inject } from '@angular/core';
import {
  type ActivatedRouteSnapshot,
  type CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { hasServiceSync } from '@auth/repository/auth.repository';
import {
  INavigationItem,
  ItemInfo,
  MENU_LABEL,
  type MenuLabel,
} from '@core/models/navigation-item.model';
import {
  ControlledViews,
  DISABLED_REASON,
  ICounterpartyOnboardingInfo,
} from '@core/models/onboarding.model';
import { Utils } from '@core/models/utils';
import { UME_SERVICE } from '@core/service-subscriptions/ume-services';
import { HOME_PAGE, PROVIDER } from '@shared/constants/ume-constants';
import { BRService } from '@shared/services/br.service';
import { NavigationService } from '@shared/services/navigation.service';
import { map, Observable } from 'rxjs';
import { getCompanyStoreValue } from 'src/app/routes/company/modules/company-details/repository/company.repository';

import {
  getSubItems,
  selectSubItems,
  updateDetailViews,
} from '../repository/navigation-items.repository';

export const navigationLinkGuardFn: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean> => {
  const router: Router = inject(Router);
  const navService: NavigationService = inject(NavigationService);

  const currentUrl: string = state.url;
  const isProvider: boolean = hasServiceSync(UME_SERVICE.PROVIDER);
  const subItems: ItemInfo[] | null = getSubItems();

  //special case for company-users
  if (currentUrl.includes('company-users') && isProvider) {
    return true;
  }

  if (subItems && subItems.length) {
    return checkLink(subItems, currentUrl, isProvider, router);
  } else {
    return navService.getCompanyNavigationLinks().pipe(
      map((items: INavigationItem[]) => {
        const updatedItems = selectSubItems(items);
        return checkLink(updatedItems, currentUrl, isProvider, router);
      })
    );
  }
};

export const navigationLinkCompanyDetailsGuardFn: CanActivateFn = (
  _route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): boolean | Observable<boolean> => {
  const router: Router = inject(Router);
  const currentUrl: string = state.url;
  const brId = Utils.parentRouteParam(state.root, 'brId');

  const views: ControlledViews[] = getCompanyStoreValue().controlledStatus?.detailsViews || [];
  const updatedViews: ControlledViews[] = updateDetailViews(views);
  const status: ICounterpartyOnboardingInfo | null = getCompanyStoreValue().controlledStatus;

  if (currentUrl.includes('restricted-user-rights')) {
    return true;
  }

  if (currentUrl.includes('under-construction')) {
    return true;
  }

  if (status && Object.keys(status).length) {
    return checkDetailViewLink(updatedViews, currentUrl, router, brId);
  } else {
    if (brId) {
      return inject(BRService)
        .getControlledStatus(brId)
        .pipe(
          map((info: ICounterpartyOnboardingInfo) => {
            const updatedItems = updateDetailViews(info.detailsViews);
            return checkDetailViewLink(updatedItems, currentUrl, router, brId);
          })
        );
    } else {
      return false;
    }
  }
};

function checkLink(
  subItems: ItemInfo[],
  currentUrl: string,
  isProvider: boolean,
  router: Router
): boolean {
  if (subItems && subItems.length) {
    const subItem: ItemInfo | undefined = subItems.find((item: ItemInfo) =>
      currentUrl.includes(item.link)
    );

    if (!subItem) {
      isProvider
        ? router.navigate(['/', PROVIDER, HOME_PAGE]).then()
        : router.navigate(['/', HOME_PAGE]).then();
    }

    if (subItem && subItem.payment) {
      router.navigate(['/', 'plans', 'comparison']);
    }

    if (subItem && subItem.disabledReason === DISABLED_REASON.UNDER_CONSTRUCTION) {
      const label: string | string[] = setRestrictedAccessRoute(subItem.menuLabel);
      router.navigate(['/', ...(Array.isArray(label) ? label : [label]), 'under-construction']);
    }

    if (subItem && subItem.disabled) {
      const label: string | string[] = setRestrictedAccessRoute(subItem.menuLabel);
      router.navigate(['/', ...(Array.isArray(label) ? label : [label]), 'restricted-user-rights']);
    }

    return true;
  }
  return false;
}

function checkDetailViewLink(
  views: ControlledViews[],
  currentUrl: string,
  router: Router,
  brId: string | null
): boolean {
  if (views && views.length) {
    const currentView: ControlledViews | undefined = views.find((item: ControlledViews) =>
      currentUrl.includes(item.link)
    );
    if (!currentView) {
      router.navigate([
        '/',
        'company',
        'my-network',
        'br',
        brId,
        'controlled',
        'counterparty',
        'profile',
      ]);
    }

    if (currentView && currentView.payment) {
      router.navigate(['/', 'plans', 'comparison']);
    }

    if (currentView && currentView.disabledReason === DISABLED_REASON.UNDER_CONSTRUCTION) {
      router
        .navigate([
          '/',
          'company',
          'my-network',
          'br',
          brId,
          'controlled',
          'counterparty',
          'under-construction',
        ])
        .then(() => true);
    }

    if (currentView && currentView.disabled) {
      router.navigate([
        '/',
        'company',
        'my-network',
        'br',
        brId,
        'controlled',
        'counterparty',
        'restricted-user-rights',
      ]);
    }

    return true;
  }
  return false;
}

export function setRestrictedAccessRoute(label: MenuLabel): string | string[] {
  switch (label) {
    case MENU_LABEL.MY_PROFILE:
      return 'my-profile';
    case MENU_LABEL.COUNTERPARTIES:
      return ['company', 'my-network'];
    case MENU_LABEL.RISK_SETTINGS:
      return 'scoring';
    case MENU_LABEL.ADMIN:
      return ['admin', 'admin-company'];
    default:
      return '';
  }
}
